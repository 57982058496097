import { useNavigate, useParams } from "react-router-dom";

import { AiOutlineArrowLeft } from "react-icons/ai";
import { NavigationStrings } from "../../constants";
import React from "react";
import { SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";

const BlogDetail: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.BLOGDETAIL);

  const blogContent: any = {
    "power-of-progressive-lenses": {
      title: "Experience the Power of Progressive Lenses with Clearviz",
      content: `Are you sick of constantly switching from reading glasses to distance glasses? Do you struggle with blurry vision when trying to focus on objects at different distances? If so, progressive lenses from Clearviz, the leading lens manufacturers in Hyderabad, may be the solution you've been looking for.
                <br>
                <strong>What are Progressive Lenses?</strong>
                <br>
                Progressive lenses are a type of multifocal lens that allows you to see clearly at all distances, from near to far. Bifocal or trifocal lenses have distinct lines separating the different focal lengths. But the progressive lenses have a smooth, seamless transition between the different zones.
                <br>
                <strong>Benefits of Progressive Lenses</strong>
                <br>
                1. Convenience: With progressive lenses, you no longer need to switch between different pairs of glasses to see clearly at different distances.
                <br>
                2. Comfort: Progressive lenses reduce eye strain and fatigue, making them ideal for people who spend a lot of time reading, working on computers, or driving.
                <br>
                3. Cosmetic Appeal: Progressive lenses have no visible lines or bifocal segments, making them a more cosmetically appealing option.
                <br>
                4. Wide Field of Vision: Progressive lenses provide a wider field of vision than traditional bifocal or trifocal lenses, making it easier to see objects at different distances.
                <br>
                5. Customization: At Clearviz, our progressive lenses are customized to meet your individual needs and prescription.
                <br>
                <strong>Experience the Clearviz Difference</strong>
                <br>
                At Clearviz, we use the latest technology and highest-quality materials to create progressive lenses tailored to your unique needs. Our experienced team of opticians and lens specialists will work with you to create a customized lens solution that provides you with clear, comfortable vision at all distances.
                <br>
                Reach us today to shop the best-in-class progressive lenses and schedule an appointment with one of our experienced opticians.`,
    },
    "right-lenses-for-your-eyesight": {
      title:
        "Choosing the Right Lenses for Your Eyesight: A Comprehensive Guide",
      content: `When it comes to correcting vision problems, choosing the right lenses can make all the difference. With so many options available, it can be overwhelming to decide which lenses are best suited for your eyesight. In this blog post, we will break down the different types of lenses and which ones are ideal for various eyesight conditions.
                <br>
                <strong>Understanding Your Eyesight</strong>
                <br>
                There are several common vision problems, including:
                <br>
                1. Myopia (nearsightedness): Difficulty seeing distant objects.
                <br>
                2. Hyperopia (farsightedness): Difficulty seeing close-up objects.
                <br>
                3. Astigmatism: Blurred vision at all distances.
                <br>
                4. Presbyopia: Age-related loss of near vision.
                <br>
                <strong>Types of Lenses</strong>
                <br>
                1. Single Vision Lenses: Correct a single vision problem, such as myopia or hyperopia.
                <br>
                2. Bifocal Lenses: Have two distinct parts: one for distance vision and one for near vision.
                <br>
                3. Progressive Lenses: Provide a seamless transition between distance, intermediate, and near vision.
                <br>
                4. Toric Lenses: Designed to correct astigmatism, available in single vision, bifocal, and progressive forms.
                <br>
                5. Aspheric Lenses: Thinner and lighter, ideal for people with high prescriptions.
                <br>
                Consult an eye care professional to determine the best lenses for your unique eyesight conditions.`,
    },
    "know-about-bifocal-lenses": {
      title: "All You Need to Know About Bifocal Lenses",
      content: `Are you tired of constantly changing between reading glasses and distance glasses? Do you struggle with blurry vision when trying to focus on objects at different distances? If so, bifocal lenses may be the solution you've been looking for.
                <br>
                <strong>What are Bifocal Lenses?</strong>
                <br>
                Bifocal lenses are a type of eyeglass lens that allows you to see clearly at two different distances. The lens is divided into two distinct segments: the top segment is for distance vision, while the bottom segment is for near vision. This type of design allows you to switch between the two segments seamlessly, without having you to switch between different pairs of glasses.
                <br>
                <strong>Benefits of Bifocal Lenses</strong>
                <br>
                 Bifocal lenses offer several benefits, including:
                <br>
                1. Convenience: With bifocal lenses, you don't have to constantly switch between different pairs of glasses.
                <br>
                2. Improved vision: Bifocal lenses provide clear vision at multiple distances, reducing eye strain and fatigue.
                <br>
                3. Customization: Bifocal lenses can be customized to meet your specific needs, including the placement and size of the near and distance segments
                <br>
                <strong>Types of Bifocal Lenses</strong>
                <br>
                There are many types of bifocal lenses available:
                <br>
                1. Traditional bifocal lenses: These types of lenses have a visible line separating the near and distance segments.
                <br>
                2. Progressive bifocal lenses: These lenses have a gradual transition between the near and distance segments, without a visible line.
                <br>
                3. Digital bifocal lenses: These types of lenses use advanced technology to create a customized, digital bifocal design.
                <br>
                <strong>Where to Buy Bifocal Lenses in Hyderabad</strong>
                <br>
                If you're looking for high-quality bifocal lenses in Hyderabad, look no further than Clearviz. Clearviz is a leading provider of eyeglass lenses, including bifocal lenses, from top manufacturers. Our expert team will help you find the perfect bifocal lenses to meet your needs and budget.
                <br>
                Bifocal lenses are a convenient and effective solution for people who need to see clearly at multiple distances. With Clearviz, you can trust that you're getting high-quality bifocal lenses from top manufacturers. Reach us today to learn more and make a difference in vision.
                `,
    },
    "understanding-spatial-vision": {
      title: "Understanding Spatial Vision: What is it and How to Improve it?",
      content: `Spatial vision, also known as visual-spatial skills, refers to the ability to perceive and understand the relationships between objects in space. It involves being able to visualize and manipulate objects mentally, taking into account their size, shape, position, and movement. Spatial vision is essential for various daily activities, such as navigating through spaces, reading maps, and even playing sports.
                <br>
                <strong>Why is Spatial Vision Important?</strong>
                <br>
                Spatial vision plays a crucial role in our daily lives, and its importance cannot be overstated. Here are a few reasons why spatial vision is vital:
                <br>
                1. Navigation: Spatial vision helps us navigate through unfamiliar spaces, avoiding obstacles and finding our way.
                <br>
                2. Problem-solving: It enables us to visualize and solve problems, such as assembling furniture or packing luggage.
                <br>
                3. Sports and games: Spatial vision is essential for playing sports, such as basketball, soccer, or tennis, where players need to track the movement of the ball and other players.
                <br>
                <strong>Options to Improve Spatial Vision</strong>
                <br>
                Fortunately, spatial vision can be improved with practice and training. Here are some options to enhance your spatial vision:
                <br>
                1. Puzzles and brain teasers: Engage in activities that challenge your spatial vision, such as jigsaw puzzles, Rubik's Cube, or brain teasers.
                <br>
                2. Video games: Play video games that require spatial awareness, such as Minecraft, Tetris, or first-person shooter games.
                <br>
                3. Sports and physical activities: Participate in sports or physical activities that require spatial awareness, such as basketball, soccer, or rock climbing.
                <br>
                4. Mental visualization exercises: Practice mental visualization exercises, such as imagining objects or spaces, to improve your spatial vision.
                <br>
                5. Training programs: Utilize training programs or apps specifically designed to improve spatial vision, such as Spatial Reasoning or Visual-Spatial Skills.
                <br>
                By incorporating these options into your daily routine, you can improve your spatial vision and enhance your overall cognitive abilities. Reach Clearviz for best contact lenses in Hyderabad (link to Website)
                `,
    },
  };

  const blog = blogContent[name || ""];

  if (!blog) {
    return (
      <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title={`Blog - ${name}`}
        description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
        keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
        name={`Blog - ${name}`}
        type="website"
        canonicallink={UrlLink}
      />
      <div
        className="mx-auto px-4 py-4 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <AiOutlineArrowLeft size={30} className="text-black text-2xl mr-2" />
        <span className="text-black text-lg">Back</span>
      </div>
      <div className="flex justify-center items-center h-screen text-gray-600">
        <p className="text-xl">Blog not found</p>
      </div>
      </section>
    );
  }

  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title={`Blog - ${name}`}
        description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
        keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
        name={`Blog - ${name}`}
        type="website"
        canonicallink={UrlLink}
      />
      <div
        className="mx-auto px-4 py-4 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <AiOutlineArrowLeft size={30} className="text-black text-2xl mr-2" />
        <span className="text-black text-lg">Back</span>
      </div>
      <section className="max-w-5xl mx-auto px-4 py-12">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
          <header className="bg-primary p-6 flex items-center">
            <h1 className="text-4xl font-semibold text-white">{blog.title}</h1>
          </header>
          <article
            className="p-8 text-gray-700 leading-loose"
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </div>
      </section>
    </section>
  );
};

export default BlogDetail;
