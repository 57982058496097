import { NavigationStrings } from "../constants";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-primary w-full max-w-screen-2xl mx-auto px-custom-padding py-[2.45rem] text-white mt-[2.45rem]">
      <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-12">
        <section className="flex flex-col gap-6">
          <ul className="flex flex-col gap-2">
            <li
              className="text-tinybase font-tinybase cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.CLEARVIZLENSES);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Clearviz Lenses
            </li>
            <li
              className="text-tinybase cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.AboutUsOptions.CLEARVIZBYEYEGEAR);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              About Us
            </li>
            <li
              className="text-tinybase cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.CAREERS);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Careers
            </li>
            <li
              className="text-tinybase cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.BLOG);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Blogs
            </li>
            <li
              className="text-tinybase cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.CONTACTUS);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Contact Us
            </li>
          </ul>
          <div className="flex items-center gap-2">
            <p
              className="text-[12px] cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.PRIVACYPOLICY);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Privacy Policy
            </p>
            <p>|</p>
            <p
              className="text-[12px] cursor-pointer transition-all hover:text-slate-300"
              onClick={() => {
                navigate(NavigationStrings.COOKIEPOLICY);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Cookie Policy
            </p>
          </div>
        </section>
        <section>
          <div className="text-3xl font-semibold uppercase">Eyegear</div>

          <div className="py-6">
            <a
              href="https://goo.gl/maps/5NiuNHqx9g7Abw918"
              target="_blank"
              rel="noreferrer"
              className="flex-row gap-[0.45rem] transition-all"
            >
              <li className="text-[13px]">Eyegear Optics India Pvt. Ltd.,</li>
              <li className="text-[13px]">Road #2, House No # 8-2-277/A/9,</li>
              <li className="text-[13px]">
                Soudagar's Silver Breeze, Banjara Hills,
              </li>
              <li className="text-[13px]">
                Hyderabad &#45; 500034. Telangana, India.
              </li>
            </a>
          </div>
          <div className="text-[13px]">
            &copy; Eyegear Optics India Pvt. Ltd. {new Date().getFullYear()}
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
