import BiFocalRxImg from "../../assets/images/clearviz-lenses/lens-portfolio/bifocalrx/Bifocal1.webp";
import EgDigital20 from "../../assets/images/clearviz-lenses/lens-portfolio/egdigitalpal/EG Digital 20.webp";
import Lenscatalog from "../../assets/images/home/lenscatalog.webp";
import { Link } from "react-router-dom";
import { NavigationStrings } from "../../constants";
import React from "react";
import { SEO } from "../../components";
import SpatialVision from "../../assets/images/clearviz-lenses/lenstechnologies/Spatial Vision.png";
import UrlConstants from "../../constants/urlConstants";

const BlogList: React.FC = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.BLOG);
  const blogs = [
    {
      id: 1,
      title: "Experience the Power of Progressive Lenses with Clearviz",
      img: EgDigital20,
      name: "power-of-progressive-lenses",
    },
    {
      id: 2,
      title:
        "Choosing the Right Lenses for Your Eyesight: A Comprehensive Guide",
      img: Lenscatalog,
      name: "right-lenses-for-your-eyesight",
    },
    {
      id: 3,
      title: "All You Need to Know About Bifocal Lenses",
      img: BiFocalRxImg,
      name: "know-about-bifocal-lenses",
    },
    {
      id: 4,
      title: "Understanding Spatial Vision: What is it and How to Improve it?",
      img: SpatialVision,
      name: "understanding-spatial-vision",
    },
  ];

  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Blogs - Clearviz"
        description="Explore career opportunities at Ben Franklin. Learn about our values, culture of learning and rewards, and the benefits of joining our dynamic and diverse team."
        keywords="Ben Franklin careers, optical retail jobs, career opportunities, company values, work culture, employee rewards, learning and development"
        name="Blogs"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="w-full max-w-screen-2xl mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Blogs</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-lg rounded-lg p-6 border border-gray-200 flex flex-col items-center"
            >
              <img
                src={blog.img}
                alt={blog.title}
                className="w-full h-40 object-cover mb-4 rounded"
              />
              <Link
                to={`/blog/${blog.name}`}
                className="text-lg font-semibold text-blue-600 hover:underline text-center"
              >
                {blog.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogList;
